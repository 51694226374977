import React, { useEffect, useState } from 'react'
//import { useParams } from 'react-router-dom'
import { Spinner } from './Spinner';
import Avatar from './Avatar';
import MoviesGrid from './MoviesGrid.jsx';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

const PerformerPage = () => {
    const [isLoading, setIsLoading] = useState(true);
    //const { performerId } = useParams();
    const [performer, setPerformer] = useState(null);
    const [movies, setMovies] = useState([])

    const navigate = useNavigate();
    const location = useLocation();
    const { performerId } = useParams();


    useEffect(() => {

        async function fetchData() {

            setIsLoading(true);

            if(location.state) {
                setPerformer(location.state);               
            }
            else {
                const opts = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiYWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNTQyNDE0Mn0.ff04JTBqUjF0J4F870dievmhB8vGt3XwhnRlIgEDRbg',
                        'Content-Type': 'application/json'
                    }
                };
    
                let response = await fetch(`https://ebi-jav.av-index.workers.dev/performers/${performerId}`, opts);

                if (response.ok) {
                    let performer = await response.json();
                    setPerformer(performer);
                }
                else {
                    setPerformer(null);
                }
            }

            let movies = null;

            try {
                movies = require(`../data/movies/performer${performerId}.json`);
                setMovies(movies);
            } catch (error) { }

            if (!movies) {
                const api = require('../utils/api.js');
                movies = await api.getPerformerMovies(performerId);
                console.log(movies);
                setMovies(movies);
            }

            //lastPerformerMovies({ performer: performer, movies: movies });

            setIsLoading(false);
        }

        fetchData();

    }, [performerId])

    function movieClicked(movie) {
        navigate(`/movies/${movie.Id}`);
    }

    if (isLoading) {
        return <Spinner />
    }

    if (performer === null) {
        return <div>NO DATA</div>;
    }

    return (
        <div>
            <h3>Movies: {movies.length}</h3>
            <MoviesGrid movies={movies} movieClicked={movieClicked}/>
        </div>
    )
}

export default PerformerPage