import React, { useEffect, useState } from 'react'
import styles from '../css/Avatar.module.css'

const Avatar = ({ performer, performerClicked }) => {
    const [imageUrl, setImageUrl] = useState(null);   

    useEffect(() => {

        if(performer.Headshot)
        {
            setImageUrl(performer.Headshot);
        }
        else
        {
            setNotFoundImage();
        }

    }, []);

/*     function handleImageError() {
        setNotFoundImage();
    } */

    function onClick() {
        performerClicked(performer);
    }

    function setNotFoundImage() {
        setImageUrl('https://previews.123rf.com/images/apoev/apoev1901/apoev190100092/126178797-person-gray-photo-placeholder-woman-in-costume-on-gray-background.jpg');
    }


    return (
        <div className={styles.container} onClick={onClick}>
            <div className={styles.imageContainer}>
                <img className={styles.image} src={imageUrl} onError={setNotFoundImage} alt={performer.Name} />
            </div>

            <h4>{performer.Name}</h4>
        </div>
    )
}

export default Avatar