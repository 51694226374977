import { BrowserRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';

import Layout from './Layout';
import MoviesPage from './components/MoviesPage';
import MoviePage from './components/MoviePage';
import PerformersPage from './components/PerformersPage';
import PerformerPage from './components/PerformerPage';

function App() {
  return (
<BrowserRouter>
					<Routes>
						<Route path='/' element={ <Layout /> }>
							{/* <Route index element={<Home />} /> */}
		
							<Route path='/movies' element={<MoviesPage />} />
		
							<Route path='/movies/:movieId' element={<MoviePage />} />
		
							<Route path='/performers' element={<PerformersPage />}/>
							
							<Route path='/performers/:performerId' element={<PerformerPage/>}/>
		
						</Route>
		
						{/* <Route path='/login' element={<Login setLoggedFunc={(b) => setLogged(b)}/>}/> */}
		
						<Route path='*' element={<Navigate replace to={'/'} />} />
					</Routes>
		
				</BrowserRouter>
  );
}

export default App;
