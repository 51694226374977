import { useNavigate, useLocation } from 'react-router-dom'
import React, { useContext, useState } from 'react'
import MoviesGrid from './MoviesGrid'

const MoviesPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [collection, setCollection] = useState('');
  const [ movies, setMovies ] = useState([]);

  const [collections, setCollections] = useState([
      { value: 'collection_week', label: 'Fanza Digital Best Last Week' },
      { value: 'collection_month', label: 'Fanza Digital Best Last Month' },
      { value: 'collection_2024', label: 'Fanza Digital Best Current Year' },
      { value: 'collection_2023', label: 'Fanza Digital Best 2023' },
      { value: 'collection_2022', label: 'Fanza Digital Best 2022' }
  ]);

  function handleMovieClicked(movie) {
    //navigate(`/movies/${movie.Id}`, { state: movie });
    navigate(`/movies/${movie.Id}`);
}

const loadMovieCollection = (col) => {
    //console.log(col);
    //setActiveTab(tabName);

    if (col) {           
        setMovies(require(`../data/movies/${col}.json`));
        setCollection(col);
    }


    //navigate('.', { replace: true, state: json });

    //location.state = { collectionName: col, movies: json };

};


  return (
    <div>
    <select value={collection} onChange={(e) => loadMovieCollection(e.target.value)}>  
    <option value={''} hidden>Choose a Collection</option>              
        {collections.map(c => <option key={c.value} value={c.value}>{c.label}</option>)}
    </select>

    <MoviesGrid movies={movies} movieClicked={handleMovieClicked} />
</div>


  )
}

export default MoviesPage