
export async function findMovies(search, accessToken) {

    var ftsSearch = search.replace(/\b\w+-\w+\b/g, function (match) {
        return '"' + match + '"';
    });

    const opts = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ text: ftsSearch })
    };

    let response = await fetch('https://ebi-jav.av-index.workers.dev/movies/search', opts);

    if (response.ok) {
        return response.json();
    }

    return [];
}

export async function getPerformerMovies(performerId) {

    const opts = {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiYWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNTQyNDE0Mn0.ff04JTBqUjF0J4F870dievmhB8vGt3XwhnRlIgEDRbg',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ actor: parseInt(performerId) })
    };

    let response = await fetch(`https://ebi-jav.av-index.workers.dev/movies/search`, opts);

    if (response.ok) {
        return response.json();
    }

    return [];
}

export async function findPerformers(name, accessToken) {

    //Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiYWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNTQyNDE0Mn0.ff04JTBqUjF0J4F870dievmhB8vGt3XwhnRlIgEDRbg

    const opts = {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: name })
    };

    let response = await fetch(`https://ebi-jav.av-index.workers.dev/performers/search`, opts);

    if (response.ok) {
        return response.json();
    }

    return [];

}

export async function getVideoFiles(id, accessToken) {

    const opts = {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    };

    let response = await fetch(`https://ebi-jav.av-index.workers.dev/videos/${id}`, opts);

    if (response.ok) {
        return response.json();
    }

    return [];
}
