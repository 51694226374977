import React, { useState } from 'react'
import styles from '../css/PerformersGrid.module.css'
import Avatar from './Avatar';

export default function PerformersGrid({ performers, performerClicked }) {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <ul className={styles.performersGrid}>
        {performers
            ? performers.map(p => (<Avatar key={p.Id} performer={p} performerClicked={performerClicked} />))
            : <p>Nada para mostrar</p>}
    </ul>
      )
}

