import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { Spinner } from './Spinner';
import styles from '../css/MoviePage.module.css'
import Avatar from './Avatar'
import { VideoJS } from './VideoJS'
import 'video.js/dist/video-js.css';
import '../css//videojsPlus.css';

const api = require('../utils/api');
const helper = require('../utils/helper');

const MoviePage = () => {
    const [isLoading, setisLoading] = useState(true);
    const [movie, setMovie] = useState(null);
    const [poster, setPoster] = useState(null);
    const [videos, setVideos] = useState([]);

    let selectVideoRef = useRef(null);
    let selectQualityRef = useRef(null);

    const navigate = useNavigate();
    const location = useLocation();
    const { movieId } = useParams();

    //console.log(location);

    useEffect(() => {

        if (movie == null) {
            async function fetchData() {

                setisLoading(true);

                const opts = {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiYWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNTQyNDE0Mn0.ff04JTBqUjF0J4F870dievmhB8vGt3XwhnRlIgEDRbg',
                    },
                };

                let response = await fetch(`https://ebi-jav.av-index.workers.dev/movies/${movieId}`, opts);

                setisLoading(false);

                if (response.ok) {
                    let movie = await response.json();
                    //console.log(movie);
                    setMovie(movie);

                    let videos = movie.Videos.sort(compareVideos);

                    setVideos(videos);

                    //console.log(movie.Videos);

                    if (movie.Poster != null) {
                        setPoster(movie.Poster);
                    }

                    //location.state = { movie: movie, videos: videos };
                    //navigate('.', { replace: true, state: { movie: movie, videos: videos } });
                }
                else {
                    //console.log('movie es null');
                    setMovie(null);
                }
            }

            fetchData();
        }




    }, [movieId]);

    const performerClicked = (performer) => {
        navigate(`/performers/${performer.Id}`, { state: performer });
    }

    const handlePlayerReady = (player) => {
        // Manejar eventos del reproductor aquí
    };

    function compareVideos(a, b) {
        if (a.Height !== b.Height) {
            return b.Height - a.Height;
        } else {
            return a.Part - b.Part;
        }
    }

    if (isLoading) {
        return <Spinner />
    }

    if (!movie) {
        return <div>NOT FOUND</div>;
    }

    if (videos != null) {
        //console.log(videos);
    }

    return (
        <div className={styles.grid}>

            <div className={`${styles.gridItem} ${styles.video}`}>
                <VideoJS videos={videos} poster={poster} onReady={handlePlayerReady} />
            </div>

            <div className={`${styles.gridItem} ${styles.details}`}>
                <p>
                    <strong>Title:</strong> {movie.Title}
                </p>
                {movie.ProductId ? <p><strong>ProductId:</strong>{movie.ProductId}</p> : ''}
                <p>
                    <strong>Genres:</strong>{" "}
                    {movie.Categories.map((category) => category.Name).join(", ")}
                </p>

                {movie.Description ? <p><strong>Description:</strong>{movie.Description}</p> : ''}

            </div>

            <div className={`${styles.gridItem} ${styles.performers}`}>
                {movie.Performers != null &&
                    (<><p><strong>Performers:</strong></p>
                        <div className={styles.avatarContainer}>
                            {
                                movie.Performers.map(p => (
                                    <Avatar key={p.Id} performer={p} performerClicked={performerClicked}/>
                                ))
                            }
                        </div></>)
                }
            </div>
        </div>


    )
}

export default MoviePage