import React, { useState, useContext } from 'react'
import styles from '../css/PerformersPage.module.css'
//import MainMenu from './MainMenu';
import PerformerPage from './PerformerPage';
import MoviePage from './MoviePage';
import PerformersGrid from './PerformersGrid';
import { useNavigate } from 'react-router-dom';

const PerformersPage = () => {
  const [activePanel, setActivePanel] = useState('panel1');
  //const [performer, setPerformer] = useState(null);
  const [movie, setMovie] = useState(null);

  const [collectionName, setCollectionName] = useState('');


  //const { performers, setPerformers } = useContext(AppContext);
  const [ performers, setPerformers ] = useState([]);

  const navigate = useNavigate();

  function performerClicked(performer) {

    navigate(`/performers/${performer.Id}`, { state: performer });
    //setPerformer(p);
    //setActivePanel('panel2');
  }

  function movieClicked(m) {
    //setMovie(m);
    //setActivePanel('panel3');
  }

  function loadCollection(collName) {

    try {
      if (collName) {
        setPerformers(require(`../data/performers/${collName}.json`));
        setCollectionName(collName);
      }

    } catch (error) {

    }
  }

  return (
    /*     <View activePanel={activePanel} >
          <Panel id="panel1">
            <PanelHeader after={menu}>
              <PanelHeaderContent aside={<Icon16Dropdown
                style={{
                  transform: `rotate(${mainMenuOpened ? '180deg' : '0'})`,
                }}
              />} onClick={toggleMainMenu} >
                Performers
              </PanelHeaderContent>
            </PanelHeader>
            <MainMenu changeView={changeView} mainMenuOpened={mainMenuOpened} toggleMainMenu={toggleMainMenu} />
            <NativeSelect onChange={(e) => loadCollection(e.target.value)}>
    
              <option value="year2023">Fanza Ranking 2023</option>
              <option value="year2022">Fanza Ranking 2022</option>
              <option value="year2021">Fanza Ranking 2021</option>
              <option value="year2020">Fanza Ranking 2020</option>
              <option value="year2019">Fanza Ranking 2019</option>
              <option value="year2018">Fanza Ranking 2018</option>
              <option value="year2017">Fanza Ranking 2017</option>
              <option value="year2016">Fanza Ranking 2016</option>
    
            </NativeSelect>
            <Performers performers={performers} performerClicked={performerClicked} />
          </Panel>
    
          <Panel id="panel2">
            <PanelHeader before={<PanelHeaderBack onClick={() => setActivePanel('panel1')} />}>
              <PanelHeaderContent>
                {performer != null ? performer.Name : null}
              </PanelHeaderContent>
            </PanelHeader>
            {
              performer != null ? (<PerformerPage performerId={performer.Id} movieClicked={movieClicked} lastPerformerMovies={(e) => {
                console.log(e);
                //setlastPerformerMovies(e);
    
              }} />) : null
            }
    
          </Panel>
    
          <Panel id="panel3">
            {movie != null && (
              <>
                <PanelHeader before={<PanelHeaderBack onClick={() => setActivePanel('panel2')} />} >
                  {movie.Title}
                </PanelHeader>
                <MoviePage movieId={movie.Id} />
              </>)
            }
          </Panel>
        </View> */
    <div>
      <select value={collectionName} onChange={e => loadCollection(e.target.value)}>
        <option value={''} hidden>Choose a Collection</option>
        <option value="year2023">Fanza Ranking 2023</option>
        <option value="year2022">Fanza Ranking 2022</option>
        <option value="year2021">Fanza Ranking 2021</option>
        <option value="year2020">Fanza Ranking 2020</option>
        <option value="year2019">Fanza Ranking 2019</option>
        <option value="year2018">Fanza Ranking 2018</option>
        <option value="year2017">Fanza Ranking 2017</option>
        <option value="year2016">Fanza Ranking 2016</option>
      </select>
      <PerformersGrid performers={performers} performerClicked={performerClicked} />
    </div>

  )
}

export default PerformersPage


