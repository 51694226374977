import React, { useRef, useState, useEffect } from 'react';
import videojs from 'video.js';

const api = require('../utils/api');
const helper = require('../utils/helper');

export const VideoJS = (props) => {
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  const [actualVideo, setActualVideo] = useState(0);
  const [qualities, setQualities] = useState([]);

  const videosMenuRef = useRef(null);
  const qualityMenuRef = useRef(null);
  const videoIconRef = useRef(null);
  const qualityIconRef = useRef(null);

  const [options, setOptions] = useState({
    autoplay: false,
    controls: true,
    responsive: true,
    playbackRates: [0.5, 1, 1.5, 2],
    fluid: true,
    sources: []
  });

  const { videos, poster, onReady } = props;

  React.useEffect(() => {
    if (!playerRef.current) {
      const videoElement = document.createElement("video");
      videoElement.classList.add('video-js');
      videoRef.current.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, options, () => {
        onReady && onReady(player);

        createQualitiesMenu();
      });

    } else {
      const player = playerRef.current;
      player.autoplay(options.autoplay);
      player.poster(options.poster);
      player.src(options.sources);
    }
  }, [options, videoRef]);

  useEffect(() => {

    if (videosMenuRef.current == null) {
      createVideosMenu();
    }

    //console.log(videos);

    videos.map(v => {
      let button = document.createElement("div");

      button.classList.add('item');

      button.id = `vid${v.Id}`;
      button.innerText = `${v.Part === null ? 'Full Movie' : 'Movie Part #' + v.Part} (${v.Height}P) - ${helper.convertSecondsToTime(v.Duration)}`;

      button.addEventListener("click", (e) => {
        let videoId = e.target.id.substring(3);
        var source = videos.find(v => v.Id == videoId);

        console.log('source', source);

        if (source != null && videoIconRef.current != null) {
          videoIconRef.current.innerText = `${source.Part === null ? 'FULL' : '#' + source.Part}`;
          setActualVideo(source);
        }

        var menuButtons = videosMenuRef.current.children;

        for (let index = 0; index < menuButtons.length; index++) {
          const b = menuButtons[index];

          if (b.id === button.id) {
            button.classList.add("selected");
          }
          else {
            b.classList.remove("selected");
          }
        }
      });

      videosMenuRef.current.appendChild(button);

    });

    if (videos && videos.length > 0) {
      setActualVideo(videos[0]);
    }

  }, [videos])

  useEffect(() => {

    if (actualVideo !== null && actualVideo.Id != undefined) {

      async function fetchData() {
        var filesObj = await api.getVideoFiles(actualVideo.Id, 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoiYWRtaW5pc3RyYXRvciIsInJvbGUiOiJhZG1pbiIsImlhdCI6MTcwNTQyNDE0Mn0.ff04JTBqUjF0J4F870dievmhB8vGt3XwhnRlIgEDRbg');

        var q = Object.entries(filesObj).map(([key, value]) => ({ height: parseInt(key.substring(5)), url: value }));

        setQualities(q);

        if (videoIconRef.current != null) {

console.log(actualVideo);

           videoIconRef.current.innerText = `${actualVideo.Part === null ? 'FULL' : '#' + actualVideo.Part}`;
          qualityIconRef.current.innerText = q[0].height;
          addUrlToPlayer(q[0].url);
        }

      };

      fetchData();

    }
    else {
    }

  }, [actualVideo])

  useEffect(() => {

    const player = playerRef.current;

    if (player != null && qualities.length > 0) {

      let className = "item";
      let father = player.controlBar.el_;

/*        let sorted = qualities.sort((a, b) => {
        return a.height > b.height ? 0 : 1;
      }); */

      //console.log(sorted);

      qualityMenuRef.current.innerHTML = '';

      qualities.reverse().map(q => {
        let button = document.createElement("div");

        button.classList.add(className);

        button.id = `${q.height}`;
        button.innerText = q.height + "p";

        button.addEventListener("click", (e) => {
          let qualityToPlay = parseInt(e.target.id);
          var source = qualities.find(q => q.height === qualityToPlay);

          if (source != null && qualityIconRef.current != null) {
            qualityIconRef.current.innerText = source.height;
            addUrlToPlayer(source.url);
          }

          var menuButtons = qualityMenuRef.current.children;

          for (let index = 0; index < menuButtons.length; index++) {
            const b = menuButtons[index];
  
            if (b.id === button.id) {
              b.classList.add("selected");
            }
            else {
              b.classList.remove("selected");
            }
          }
        });

        qualityMenuRef.current.appendChild(button);
      });

    }
  }, [qualities])


  function addUrlToPlayer(url) {

    setOptions(previous => ({
      ...previous,
      sources: [{ src: url, type: 'video/mp4' }],
      poster: poster
    }));
  }

  function createVideosMenu() {
    const player = playerRef.current;

    let father = player.controlBar.el_;
    let contentMenu = document.createElement('div');
    let menu = document.createElement('div');
    let icon = document.createElement('div');

    let fullscreen = father.querySelector('.vjs-fullscreen-control');
    contentMenu.appendChild(icon);
    contentMenu.appendChild(menu);
    fullscreen.before(contentMenu);

    videosMenuRef.current = menu;
    videoIconRef.current = icon;

    menu.classList.add('menu');
    icon.classList.add('icon');
    contentMenu.classList.add('contentMenu', 'videosContentMenu');
  }

  function createQualitiesMenu() {
    const player = playerRef.current;

    let father = player.controlBar.el_;
    let contentMenu = document.createElement('div');
    let menu = document.createElement('div');
    let icon = document.createElement('div');

    let fullscreen = father.querySelector('.vjs-fullscreen-control');
    contentMenu.appendChild(icon);
    contentMenu.appendChild(menu);
    fullscreen.before(contentMenu);

    qualityMenuRef.current = menu;
    qualityIconRef.current = icon;
    menu.classList.add('menu');
    //icon.classList.add('icon', 'vjs-icon-cog');
    icon.classList.add('icon');
    contentMenu.classList.add('contentMenu');
  }

  /*   function getVideoString(v) {
  
      return `${v.Part === null ? 'Full Movie' : 'Movie Part #' + v.Part} (${v.Height}P) - ${helper.convertSecondsToTime(v.Duration)}`;
    } */

  React.useEffect(() => {
    const player = playerRef.current;
    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);



  return (
    <div data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
};