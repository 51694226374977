import styles from '../css/MovieCard.module.css'

export default function MovieCard({ movie, movieClicked }) {

    return (
        //style={{backgroundImage: `url('${movie.Poster}')`}}
        <li className={styles.movieCard}  onClick={() => movieClicked(movie)}>
            <img                           
                className={styles.movieImage}
                loading="lazy"
                src={movie.Poster}
                alt={`Poster of ${movie.ProductId}`}
            />
            <div>{movie.title}</div>
        </li>
    )
}
