
export function convertSecondsToTime(seconds) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secsRemainder = seconds % 60;

    // Formatear los valores para que tengan dos dígitos
    const hoursStr = hours.toString().padStart(2, '0');
    const minutesStr = minutes.toString().padStart(2, '0');
    const secondsStr = secsRemainder.toString().padStart(2, '0');

    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}