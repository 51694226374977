import React, { useEffect, useState } from 'react'
import styles from '../css/MoviesGrid.module.css'
import MovieCard from './MovieCard';

export default function MoviesGrid({ movies, movieClicked }) {
    const [isLoading, setIsLoading] = useState(true);

    return (
        <ul className={styles.moviesGrid}>
            {movies
                ? movies.map(movie => (<MovieCard key={movie.Id} movie={movie} movieClicked={movieClicked} />))
                : <p>Nada para mostrar</p>}
        </ul>
    )
}
