import React from 'react'
import { Link, Outlet } from "react-router-dom";

const Layout = () => {
    return (
        <main>
            <nav>
                <Link to={'/'}>HOME</Link>
                <Link to={'/movies'}>MOVIES</Link>
                <Link to={'/performers'}>PERFORMERS</Link>
            </nav>

            <section>
                <Outlet />
            </section>
        </main>
    )
}

export default Layout